<template>
  <div>
    <a-modal 
      :visible="visible" 
      :title="`🚨 ${translate.important_notice} 🚨`" 
      @ok="handleOk" :ok-text="translate.close" 
      :closable="false" ok-type="default" 
      :cancel-button-props="{ style: { display: 'none' } }"
    >
        <p class="warning-modal-text">
          {{ translate.important_notice_description }}
      </p>

        <a href="https://newdash.ui.datavisiooh.com/" target="_blank" class="warning-modal-link">🔗 {{ translate.new_dashboard_link }}</a>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "WarningModal",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    ...mapActions("Translate", ["getLabels"]),
    handleOk() {
      this.visible = false;
    },
    checkIfTranslationsAreLoaded() {
      if (
        this.translate && 
        this.translate.important_notice && 
        this.translate.important_notice_description && 
        this.translate.new_dashboard_link
      ) {
        this.visible = true;
      }
    }
  },
  computed: {
    ...mapState("Translate", {
      translate: (state) => state.labels,
    }),
  },
  watch: {
    translate: {
      handler(newVal) {
        if (newVal) {
          this.checkIfTranslationsAreLoaded();
        }
      },
      deep: true
    }
  },
  created() {
    this.getLabels();
    this.checkIfTranslationsAreLoaded();
  },
};
</script>

<style lang="scss" scoped>
.warning-modal-text {
  font-size: 20px;
  font-weight: 600;
  color: var(--gray-80);
  text-align: center;
}

.warning-modal-link {
  color: var(--blue-50);
  font-size: 16px;
  font-weight: 600;

  &:hover {
    color: var(--blue-100);
  }
}

:deep(.ant-modal-title) {
  text-align: center;
  font-size: 20px;
  color: rgb(203, 11, 34);
}
</style>
